// EstadosPedidos.js
import React, { useEffect, useState } from "react";

// Importações do react-bootstrap (v1.6.1)
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Importações do Material‑UI (para inputs, autocomplete e ícones)
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import InfoIcon from "@material-ui/icons/Info";
import PersonIcon from "@material-ui/icons/Person";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import exportFromJSON from "export-from-json";
import { checarPermissao, validateTokenUser, getUser, getToken } from "../../utils/utils";

function EstadosPedidos(props) {
  // Estados para os dados e filtros (inicializados como arrays)
  const [pedidos, setPedidos] = useState([]);
  const [auxPedidos, setAuxPedidos] = useState([]);
  const [pedidos2, setPedidos2] = useState([]);
  const [auxPedidos2, setAuxPedidos2] = useState([]);
  const [permitido, setPermitido] = useState(false);
  const [filtroTxt, setFiltroTxt] = useState("");
  const [filtroCol, setFiltroCol] = useState("");

  // Opções para filtro de coluna
  const filtroColOptions = [
    { label: "Status", value: "status" },
    { label: "Pedido", value: "DocPedido" },
    { label: "Produto", value: "CodItem" },
    { label: "Cliente", value: "Cliente" },
  ];

  // Opções para atualização de status
  const statusOptions = [
    "Aguardando Assinatura",
    "Aguardando Produto",
    "Aguardando Liberação do Cliente",
    "Aguardando Pagamento",
    "Liberado para logística",
    "Saldo Restante",
    "Aguardando Retirada do Produto (FOB)",
    "Aguardando Liberação do Crédito",
    "Liberado Parcialmente Logística",
    "Empréstimo",
    "Aguardando Desmembramento",
    "Finalizado",
    "Carteira 2024",
  ];

  useEffect(() => {
    async function fetchMyAPI() {
      // Valida o token e checa a permissão
      await validateTokenUser(props);
      setPermitido(await checarPermissao("estados_pedidos", props));

      const response = await api.get("/return_status_pedidos", {
        params: { userId: getUser(), token: getToken() },
      });

      // Filtra os pedidos que NÃO são "Finalizado" nem "Saldo Restante"
      const filter = response.data.filter(
        (item) =>
          item.status !== "Finalizado" && item.status !== "Saldo Restante"
      );
      setPedidos(filter);
      setAuxPedidos(filter.slice(0, 30));

      // Filtra os pedidos com status "Finalizado" ou "Saldo Restante"
      const filter2 = response.data.filter(
        (item) => item.status === "Finalizado" || item.status === "Saldo Restante"
      );
      setPedidos2(filter2);
      setAuxPedidos2(filter2.slice(0, 30));
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Função para baixar o contrato em PDF
  const handleDownload = async (numPedido) => {
    try {
      const response = await api.get("/retorna_excel_contrato_venda", {
        params: { numPedido, userId: getUser(), token: getToken() },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `pedido${numPedido}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  // Baixa relatório consolidado em XLS
  function baixarCarteira() {
    if (!pedidos.length) return;
    const data = pedidos.concat(pedidos2);
    const fileName = "RelCarteira";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  }

  // Atualiza o status do pedido chamando a API
  async function updatePedidos(value, doc, cod, index) {
    console.log("Atualizando pedido:", index, value);
    await api.get("/alter_status_pedido", {
      params: { value, doc, cod, index, userId: getUser(), token: getToken() },
    });
  }

  // Aplica o filtro (busca case-insensitive e sem espaços extras)
  const handleFiltro = (coluna, valor) => {
    if (!coluna || !valor.trim()) {
      // Se faltar coluna ou valor, limpa o filtro
      clearFiltro();
      return;
    }
    const filtro = valor.toLowerCase().trim();
    const filtro1 = pedidos.filter((item) => {
      const campo = item[coluna];
      return campo && campo.toString().toLowerCase().trim().includes(filtro);
    });
    const filtro2 = pedidos2.filter((item) => {
      const campo = item[coluna];
      return campo && campo.toString().toLowerCase().trim().includes(filtro);
    });
    // Limita os resultados para 30 itens (se desejar)
    setAuxPedidos(filtro1.slice(0, 30));
    setAuxPedidos2(filtro2.slice(0, 30));
  };

  // Limpa o filtro e restaura os dados originais
  const clearFiltro = () => {
    setFiltroTxt("");
    setAuxPedidos(pedidos.slice(0, 30));
    setAuxPedidos2(pedidos2.slice(0, 30));
  };

  if (!permitido) return <></>;

  return (
    <div style={{ fontSize: "13px" }}>
      {pedidos.length ? (
        <Button style={{ fontSize: "13px" }} className="mb-2" variant="primary" onClick={baixarCarteira}>
          Baixar Relatório de Carteira
        </Button>
      ) : (
        <Spinner animation="border" variant="primary" />
      )}

      {/* Card para os filtros */}
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-end">
            <Col md={4}>
              <Form.Group controlId="filtroTxt">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Filtro"
                  value={filtroTxt}
                  onChange={(e) => setFiltroTxt(e.target.value)}
                  fullWidth
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="filtroCol">
                <Autocomplete
                  options={filtroColOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, v) =>
                    v === null ? setFiltroCol("") : setFiltroCol(v.value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Coluna"
                    />
                  )}
                  fullWidth
                />
              </Form.Group>
            </Col>
            <Col md="auto">
              <Button
                variant="secondary"
                onClick={() => handleFiltro(filtroCol, filtroTxt)}
              >
                Pesquisar
              </Button>
            </Col>
            <Col md="auto">
              <Button variant="outline-secondary" onClick={clearFiltro}>
                Limpar Filtro
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Primeiro Accordion: Pedidos não finalizados */}
      <div
        style={{
          background: "white",
          padding: "15px",
          borderRadius: 5,
          maxHeight: 800,
          overflowY: "auto",
        }}
      >
        {auxPedidos.length ? (
          <Accordion defaultActiveKey="">
            {auxPedidos.map((pedido, index) => (
              <Card key={index} className="mb-3">
                <Card.Header>
                    <Row className="w-100">
                        {/* Área clicável para abrir/fechar o Accordion */}
                        <Col xs={12} md={8}> {/* Reduzindo o espaço total para o header clicável */}
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={`${index}`}
                            className="w-100 text-left p-0"
                            style={{ color: "inherit", textDecoration: "none", fontSize: 13 }}
                        >
                            <Row className="w-100">
                            <Col xs={12} md={2} className="d-flex align-items-center"> 
                                <ReceiptIcon style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 11 }}> {/* Fonte menor para o número do pedido */}
                                <strong>Pedido Nr.:</strong> {pedido.DocPedido}
                                </span>
                            </Col>
                            <Col xs={12} md={6} className="d-flex align-items-center">
                                <PersonIcon style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>
                                <strong>Cliente:</strong> {pedido.Cliente}
                                </span>
                            </Col>
                            <Col xs={12} md={4} className="d-flex align-items-center">
                                <ShoppingCartIcon style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>
                                <strong>Produto:</strong> {pedido.CodItem}
                                </span>
                            </Col>
                            </Row>
                        </Accordion.Toggle>
                        </Col>
                        <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-end mt-2 mt-md-0"
                        >
                        <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            }}
                        >
                            <InfoIcon style={{ marginRight: 5 }} />
                            <Autocomplete
                            options={statusOptions}
                            getOptionLabel={(option) => option}
                            defaultValue={pedido.status}
                            onChange={(e, v) =>
                                updatePedidos(v, pedido.DocPedido, pedido.CodItem, 0)
                            }
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Status do Pedido"
                                variant="outlined"
                                size="small"
                                style={{ minWidth: '200px', fontSize: '14px' }}  // Aumenta a largura e a fonte se necessário
                                />
                            )}
                            fullWidth
                            />
                        </div>
                        </Col>
                    </Row>
                    </Card.Header>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    <p>
                      <strong>Cliente:</strong> {pedido.Cliente}
                    </p>
                    <p>
                      <strong>Vendedor:</strong> {pedido.VendedorPedido}
                    </p>
                    <p>
                      <strong>Supervisor:</strong> {pedido.SupervisorPedido}
                    </p>
                    <p>
                      <strong>Quantidade Pedido:</strong> {pedido.QtdPedido}
                    </p>
                    <p>
                      <strong>Origem:</strong> {pedido.Origem}
                    </p>
                    <p>
                      <strong>Total Parcialmente liberado para logística:</strong>{" "}
                      {pedido.liberad_log}
                    </p>
                    <p>
                      <strong>Carteira:</strong> {pedido.Carteira}
                    </p>

                    <Form.Group controlId={`statusSelect-${index}`} className="my-3">
                      <Autocomplete
                        options={statusOptions}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) =>
                          updatePedidos(v, pedido.DocPedido, pedido.CodItem, 0)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status do Pedido"
                            variant="outlined"
                            size="small"
                          />
                        )}
                        fullWidth
                        style={{ marginTop: 25 }}
                      />
                    </Form.Group>

                    {pedido.cod === 9 && (
                      <Form.Group controlId={`logisticaInput-${index}`} className="my-3">
                        <TextField
                          type="number"
                          label="Total liberado parcialmente para logística"
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{ marginTop: 25 }}
                          onChange={(e) =>
                            updatePedidos(
                              pedido.cod,
                              pedido.DocPedido,
                              pedido.CodItem,
                              e.target.value
                            )
                          }
                        />
                      </Form.Group>
                    )}

                    {pedido.numPedidoSAP !== 0 && (
                      <Button variant="info" onClick={() => handleDownload(pedido.DocPedido)}>
                        Download Contrato Atualizado
                      </Button>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        ) : (
          <Spinner animation="border" variant="primary" />
        )}
      </div>

      {/* Segundo Accordion: Pedidos finalizados ou com saldo restante */}
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: 5,
          marginTop: 20,
          maxHeight: 600,
          overflowY: "auto",
        }}
      >
        <h5 style={{ fontWeight: 'bold' }}>
        Pedidos "Finalizados" ou "Saldo Restante"
        </h5>
        {auxPedidos2.length ? (
          <Accordion defaultActiveKey="">
            {auxPedidos2.map((pedido, index) => (
              <Card key={index} className="mb-3">
                <Card.Header>
                    <Row className="w-100">
                        {/* Área clicável para abrir/fechar o Accordion */}
                        <Col xs={12} md={8}> {/* Reduzindo o espaço total para o header clicável */}
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={`${index}`}
                            className="w-100 text-left p-0"
                            style={{ color: "inherit", textDecoration: "none", fontSize: 13 }}
                        >
                            <Row className="w-100">
                            <Col xs={12} md={2} className="d-flex align-items-center"> 
                                <ReceiptIcon style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 11 }}> {/* Fonte menor para o número do pedido */}
                                <strong>Pedido Nr.:</strong> {pedido.DocPedido}
                                </span>
                            </Col>
                            <Col xs={12} md={6} className="d-flex align-items-center">
                                <PersonIcon style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>
                                <strong>Cliente:</strong> {pedido.Cliente}
                                </span>
                            </Col>
                            <Col xs={12} md={4} className="d-flex align-items-center">
                                <ShoppingCartIcon style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 13 }}>
                                <strong>Produto:</strong> {pedido.CodItem}
                                </span>
                            </Col>
                            </Row>
                        </Accordion.Toggle>
                        </Col>
                        <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-end mt-2 mt-md-0"
                        >
                        <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            }}
                        >
                            <InfoIcon style={{ marginRight: 5 }} />
                            <Autocomplete
                            options={statusOptions}
                            getOptionLabel={(option) => option}
                            defaultValue={pedido.status}
                            onChange={(e, v) =>
                                updatePedidos(v, pedido.DocPedido, pedido.CodItem, 0)
                            }
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Status do Pedido"
                                variant="outlined"
                                size="small"
                                style={{ minWidth: '200px', fontSize: '14px' }}  // Aumenta a largura e a fonte se necessário
                                />
                            )}
                            fullWidth
                            />
                        </div>
                        </Col>
                    </Row>
                    </Card.Header>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    <p>
                      <strong>Cliente:</strong> {pedido.Cliente}
                    </p>
                    <p>
                      <strong>Vendedor:</strong> {pedido.VendedorPedido}
                    </p>
                    <p>
                      <strong>Supervisor:</strong> {pedido.SupervisorPedido}
                    </p>
                    <p>
                      <strong>Carteira:</strong> {pedido.Carteira}
                    </p>
                    <p>
                      <strong>Origem:</strong> {pedido.Origem}
                    </p>
                    <Form.Group controlId={`statusSelect2-${index}`} className="my-3">
                      <Autocomplete
                        options={statusOptions}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) =>
                          updatePedidos(v, pedido.DocPedido, pedido.CodItem, 0)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status do Pedido"
                            variant="outlined"
                            size="small"
                          />
                        )}
                        fullWidth
                        style={{ marginTop: 25 }}
                      />
                    </Form.Group>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        ) : (
          <Spinner animation="border" variant="primary" />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setToken, setUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EstadosPedidos);